import React, { Component } from 'react'
import styled from '@emotion/styled'
import Grid from 'components/Grid'
import { LogoMark } from 'components/Logo'
import ThemeSelector from 'components/ThemeSelector'
import Link from 'components/Link'

import { typography, util } from 'styles'

import { reniColors, matisseColors } from 'styles/colors'


const Wrapper = styled(ThemeSelector)`
	position: relative;
	z-index: 2;
	flex-grow: 0;
	flex-shrink: 0;
	width: 100%;
	text-align: center;
	${ util.responsiveStyles('padding-top', 130, 100, 80, 70) }
	${ util.responsiveStyles('padding-bottom', 150, 120, 100, 90) }
`

const FooterItem = styled.div`
	display: block;
	a, p {
		${ typography.h6 }
		margin: 0;
		display: inline-block;
		vertical-align: top;
		margin: .25em 0;
	}
`

const FooterLogoIcon = styled(LogoMark)`
	margin-bottom: 20px;
`

const FooterCopyright = styled(FooterItem)`
	p {
		${ typography.smallCaps }
	}
`

const Divider = styled.div`
	${ typography.h6 }
	display: inline-block;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background: currentcolor;
	vertical-align: top;
	margin: .9em 1em 0;
`

class Footer extends Component {

	getTheme () {
		const { pathname } = this.props.location
		let themeOptions = ['grey']

		if (pathname.includes('reni')) {
			themeOptions = Object.keys(reniColors)
		} else if (pathname.includes('matisse')) {
			themeOptions = Object.keys(matisseColors)
		} else {
			themeOptions = Object.keys({...reniColors, ...matisseColors})
		}

		return  themeOptions[Math.floor(Math.random() * themeOptions.length)]
	}

	render () {

		return (
			<Wrapper setTheme={this.getTheme()}>
				<Grid small="1 [12] 1">
					<div>
						<FooterLogoIcon />
						<FooterItem><Link type="capsLink" external={true} to="mailto:info@miltontextiles.com">info@miltontextiles.com</Link></FooterItem>
						<FooterItem>
							<Link type="capsLink" external={true} to="https://www.instagram.com/miltontextiles/">Instagram</Link>
							<Divider/>
							<Link type="capsLink" external={true} to="https://pinterest.com/miltontextiles">Pinterest</Link>
						</FooterItem>
						<FooterItem><p>Made in the USA</p></FooterItem>
						<FooterItem><Link type="capsLink" external={true} to="https://gordilsandwillis.com/">Site By G&W</Link></FooterItem>
						<FooterCopyright><p>ALL RIGHTS RESERVED © {new Date().getFullYear()} MILTON TEXTILES</p></FooterCopyright>
					</div>
				</Grid>
			</Wrapper>
		)
	}
}

export default Footer
